.logocontainer {
  width: 149px;

  position: relative;
}
.hamburger_menu svg {
  width: 2em;
  height: 2em;
}

.muiListText:last-child span {
  font-size: 1.8rem !important;
  font-weight: 800;
  line-height: 30px;
  width: 100%;
}
.muiListText span {
  font-size: 1.8rem !important;
  font-weight: 800;
  line-height: 30px;
  /* width: 100%; */
}
.drawer_style {
  overflow: scroll;
  inline-size: 100%;
  max-inline-size: 375px;
  block-size: 100%;
  max-block-size: 590px;
  padding: 35px 25px 25px;
}
.menueItems {
  /* width: 100%; */
  margin: auto;
  display: flex;
  align-items: center;
  gap: 80px;
  /* padding-top: 12px; */
  /* padding-left: 80px; */
}
.menueItems a {
  /* padding-top: 5px; */

  font-weight: 600;
}
.nav_internal_div {
  padding-left: 40px;
  min-height: 80px;
  align-items: center;
  display: grid;
  /* justify-content: space-between;
  width: 100%; */
}
.nav_toolbar_div {
  padding: 0px !important;
  padding-left: 0px !important;
}
.buttoncontainer {
  display: flex;
  align-items: baseline;
  /* padding-top: 12px; */
  /* gap: 20px; */
  padding-right: 40px;
}
.buttoncontainer a {
  /* padding-top: 5px; */
  padding-right: 2px;
  padding-left: 32px;
  font-weight: 600;
}
.login_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  background: transparent;
  cursor: pointer;
}
.nav_btn {
  display: inline-flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 40px;
  background: #030303;
  color: #fff;
  text-align: center;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* background: transparent; */
  cursor: pointer;
}
.anchor {
  color: #2f2f2f;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.productanchor {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

/* @media (min-width: 768px) and (max-width: 1024px) {
  .logocontainer {
    width: 148px;
    height: auto;
  }
  .menueItems {
    padding-left: 20px;
  }
  .anchor {
    font-size: 16px;
  }
  .login_btn {
    padding: 10px 16px;

    font-size: 14px;
  }
  .nav_btn {
    padding: 10px 16px;

    font-size: 14px;
  }
} */
/* @media screen and (max-width: 768px) {
  .logocontainer {
    width: 100px;
    height: auto;
  }
} */

/* Mobile view */
@media (max-width: 600px) {
  .nav_internal_div {
    padding: 0px 16px;
  }
  /* .logocontainer {
    width: 100px;
    height: auto;
  } */
  .login_btn,
  .nav_btn {
    /* padding: 5px 23px 18px; */
    font-size: 1.4rem;
  }
}

/* Tablet view */
@media (min-width: 601px) and (max-width: 960px) {
  .nav_internal_div {
    padding: 0px 20px;
  }
  /* .logocontainer {
    width: 148px;
    height: auto;
  } */
  .menueItems {
    padding-left: 20px;
  }
  .anchor {
    font-size: 1.4rem;
  }
  .login_btn,
  .nav_btn {
    /* padding: 5px 23px 18px; */
    font-size: 1.4rem;
  }
}

/* Laptop view */
@media (min-width: 961px) and (max-width: 1280px) {
  /* .logocontainer {
    width: 149px;
    height: 42;
  } */
  .anchor,
  .login_btn {
    font-size: 1.6rem;
  }

  .nav_btn {
    padding: 10px;
    font-size: 1.4rem;
  }
}
