.sm_text {
  color: #030303;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.para_bold {
  color: #030303;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.para_18_bold {
  color: #030303;
  /* font-family: Roboto; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.para_14 {
  color: #030303 !important;
  /* font-family: Roboto !important; */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: none !important;
  /* line-clamp: 1; */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sub_description {
  color: #777;
  flex: 1 0 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card_actions_btn {
  display: flex;
  padding: 10px 75px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.action_btn {
  border-radius: 4px !important;
  background: linear-gradient(0deg, #d0ee11 0%, #d0ee11 100%), #a7bf0e !important;

  padding: "10px 5px" !important;
}
.tablevaluebold {
  color: #030303;
  /* font-family: Roboto; */
  font-size: 1.4rem;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.5px;
}
