.header {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 30%;
  margin-bottom: 16px;
}
.notificationwrapper {
  border-radius: 18px;
  background: #fff;
  padding: 16px 12px 32px 26px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.notifycontainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  padding: 9px;
}
.textContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* gap: 10px; */
}
.left {
  display: flex;
  align-items: center;
  gap: 15px;
}
.right {
  display: flex;
  align-items: center;
  gap: 11px;
}
