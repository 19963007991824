.calendar-container {
  margin: 20px 0 0 0;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 10px;
  background-color: #fafafa;

  .calendar-day {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px;
  }

  .day-number {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }

  .subscriptions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4px;
  }

  .subscription-badge {
    font-size: 12px;
    color: #fff;
    padding: 4px 6px;
    margin-bottom: 2px;
    border-radius: 3px;
    white-space: nowrap;
    display: inline-block;
  }

  @media (max-width: 700px) {
    .subscription-badge {
      width: 50px;
    }
  }

  .subscription-badge:hover {
    opacity: 0.9;
    transform: scale(1.05);
    transition: 0.2s ease-in-out;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem;
    line-height: 2.5rem;
  }

  .react-datepicker__month {
    margin: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 14.28% !important;
    line-height: normal !important;
    display: table-cell;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    color: inherit;
    background: #fff;
    box-shadow: 0 0 0 1px #ebebeb;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    padding: 0;
    text-decoration: none;
    overflow: hidden;
  }

  .react-datepicker__week {
    display: table-row;
    width: 100%;
    height: 131px !important;
  }
  @media (max-width: 576px) {
    .react-datepicker__week {
      height: 76px !important;
    }
  }

  .react-datepicker__day-names {
    display: table;
    table-layout: fixed;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
  }

  .react-datepicker__day-name {
    height: auto !important;
    box-shadow: none !important;
  }

  .react-datepicker__navigation--previous {
    top: 9px;
    outline: none;
  }

  .react-datepicker__navigation--next {
    top: 9px;
    outline: none;
  }
}


@media (max-width:576px) {
  .calendar-container {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
  }
  .react-datepicker {
    box-shadow: none !important;
  }
}