.heroContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  padding-top: 50px;
  position: relative;
  /* justify-content: space-around; */
}
.container {
  padding: 74px 0px;
  background: #f2f2f2;
}
.leftSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 50px;
}
.rightSection {
  width: 50%;
}
.aladinimgContainer {
  width: 164px;
  height: 150.043px;
  top: 0;
  left: 101px;
  z-index: 0;
  position: absolute;
}
.sectionfooter {
  display: flex;
  align-items: center;
  gap: 19px;
}
.joinSecContainer {
  padding: 68px;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.extrafeaturecontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.featurescontainer {
  width: 80%;
  margin: auto;
}
.featureWrapper {
  display: grid;
  grid-template-rows: repeat(2, 1fr); /* Two rows */
  grid-template-columns: 1fr 1fr; /* Two columns */
  column-gap: 200px;
  row-gap: 50px;
}
.singleFeature {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.featuretitle {
  color: #030303;
  text-align: center;

  font-size: 2.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.testimonialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 41px;
}
.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}
.card {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.15);
}
.cardFoorer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testimonialList {
  grid-template-columns: repeat(3, 1fr) !important;
  row-gap: 30px !important;
  column-gap: 20px !important;
  overflow-y: visible !important;
}

@media (min-width: 961px) and (max-width: 1280px) {
  .heroContainer {
    padding-top: 50px;
  }

  .joinSecContainer {
    width: 80%;
  }
  .container {
    padding: 60px 0px;
  }
  .featuretitle {
    font-size: 2.4rem;
  }
}
@media (min-width: 601px) and (max-width: 960px) {
  .container {
    padding: 40px 0px;
  }
  .heroContainer {
    padding-top: 40px;
  }
  .leftSection {
    width: 100%;
    margin: auto;
    text-align: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .rightSection {
    width: 100%;
 
  }
  .joinSecContainer {
    width: 100%;
    padding: 40px;
    text-align: center;
  }
  .featureWrapper {
    column-gap: 90px;
    row-gap: 40px;

  }
  .featuretitle {
    font-size: 1.8rem;
  }
}
@media (max-width: 600px) {
  .heroContainer {
    padding-top: 30px;
  }
  .leftSection {
    width: 100%;
    margin: auto;
    text-align: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .aladinimgContainer {
    left: 50px;
    width: 100px;
    height: 50px;
  }
  .rightSection {
    width: 100%;

  }
  .joinSecContainer {
    width: 100%;
    padding: 30px 0px;
    text-align: center;
  }
  .featureWrapper {
    column-gap: 90px;
    row-gap: 40px;
    grid-template-columns: 100%;
  }
  .testimonialList {
    grid-template-columns: repeat(2, 1fr) !important;
    row-gap: 30px !important;
    column-gap: 10px !important;
    overflow-y: visible !important;
  }
  .container {
    padding: 30px 0px;
  }
  .extrafeaturecontainer {
    gap: 30px;
  }
  .featurescontainer {
    width: 100%;
  }
  .featuretitle {
    font-size: 1.6rem;
  }
  .featureWrapper {
    column-gap: 10px;
    row-gap: 20px;
  }
  .card {
    padding: 8px;
  }

}
