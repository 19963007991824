/* HelpCenter.css */

.help-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  background-color: #f4f4f4;
}

.main-heading {
  color: #333;
}

.welcome-text {
  color: #555;
}

.table-of-contents {
  max-width: 600px;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 5px;
}

.toc-heading {
  color: #000;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
}

.toc-list {
  list-style-type: none;
  padding: 0;
}
.toc-list li {
  margin-top: 8px;
}

.section-heading {
  color: #000;
  margin-top: 20px;
  margin-bottom: 26px;
}

.subsection-heading {
  color: #000;
  margin-top: 10px;
  margin-bottom: 12px;
}

.step-list {
  list-style-type: none;
  padding-left: 20px;
  margin-top: 6px;
}
.step-list li {
  margin-top: 8px;
}

/* Add more styles as needed */
