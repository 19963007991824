.footer {
  /* height: 100%; */
  width: 100%;

  background-color: #d0ee11;
  /* text-align: center; */
  /* padding: 0 70px; */
  padding-top: 64px;
}

.child {
  padding: 0 70px 64px;
  max-width: 1440px;
  margin: auto;
}
.content_1_1 {
  row-gap: 15px;
  display: flex;
  flex-direction: column;
}
.content_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.started {
  color: #030303;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
}

.started_1 {
  color: #606060;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content_1_2 button {
  padding: 20px 80px;

  font-size: 16px;

  background-color: black;
  border-radius: 30px;
  border: black;
  color: #fff;
  text-align: center;
  font-family: Roboto;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content_2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.content_2_1 {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.slide {
  flex: 33.33%;
}

.slide h2 {
  padding-bottom: 10px;
  color: #424242;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}
.slide ul {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 15px;
}
.slide ul li {
  color: #424242;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .slide ul li img {
   padding-right: 40px; 
} */

.address {
  color: #424242;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 310px;
}

.random {
  width: 310px;
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  padding-top: 10px;
}
.socialcontainer {
  width: max-content;
}
.socialcontainer > ul > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .footer {
    padding-top: 30px;
  }
  .child {
    padding: 0px 20px 20px;
  }
  .content_2 {
    flex-direction: column;
    row-gap: 30px;
  }
  .content_2_1 {
    width: 100%;
    text-align: center;
  }
  .content_2_2 {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .content_1 {
    align-items: flex-start;
  }
  .content_1_2 button {
    padding: 15px 40px;
  }
  .started {
    font-size: 3rem;
  }
  .started_1 {
    font-size: 1.6rem;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .footer {
    padding-top: 40px;
  }
  .child {
    padding: 0px 40px 40px;
  }
  .content_2 {
    flex-direction: column;
    row-gap: 30px;
  }
  .content_2_1 {
    width: 100%;
    text-align: center;
  }
  .content_2_2 {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .content_1 {
    align-items: flex-start;
  }
  .content_1_2 button {
    padding: 20px 40px;
  }
  .started {
    font-size: 4rem;
  }
  .started_1 {
    font-size: 1.8rem;
  }
}

@media (min-width: 961px) and (max-width: 1280px) {
}
