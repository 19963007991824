.hi_user {
  color: #000;

  /* Link Big */
  /* font-family: Roboto; */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.go_premium {
  color: #000;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ImgActive {
  fill: #85951e !important;
}
.imgNotActive {
  fill: #777777 !important;
}
