.subscriptionContainer {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(16%, 1fr)
  ); /* Adjust the column width as needed */
  grid-gap: 20px; /* Adjust the gap between cards as needed */
}
.cardWrapper {
  /* display: flex; */
  /* flex-direction: column; */
  height: 100%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
/* @media (min-width: 961px) and (max-width: 1280px) {} */
@media (min-width: 601px) and (max-width: 960px) {
  .subscriptionContainer {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(30%, 0.5fr)
    ); /* Adjust the column width as needed */
    grid-gap: 20px; /* Adjust the gap between cards as needed */
  }
}
@media (max-width: 600px) {
  .subscriptionContainer {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(39%, 0.5fr)
    ); /* Adjust the column width as needed */
    grid-gap: 20px; /* Adjust the gap between cards as needed */
  }
}
