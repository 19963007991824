.dashBoardContainer {
  display: flex;
  gap: 22px;
}
.mainContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  /* width: 100%; */
}


@media (min-width: 100px) and (max-width: 700px) {

  .mainContainer {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  
    /* width: 100%; */
  }
 







  /* .child_2_4 {
    margin-bottom: 8rem;
  } */
}

.sideContainer {
  flex: 20%;
}
.mainheader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
