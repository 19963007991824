/* DatePickerStyles.css */
/* .react-datepicker__day--highlight {
    background-color: #ffd700;
    color: #fff;
    border-radius: 50%;
  }
  
  .subscription-details {
    margin-top: 4px;
  }
  
  .day {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .react-datepicker__day {
    position: relative;
  }
  
  img {
    margin-top: 2px;
  } */
  
  .custom-calendar-container {
    position: relative;
  }
  
  .custom-calendar .highlighted-date {
    background-color: #1abc9c;
    color: white;
    border-radius: 50%;
  }
  
  .custom-calendar .react-datepicker__day--selected {
    background-color: transparent;
    color: black;
  }
  
  .custom-tooltip {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    z-index: 100;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .custom-tooltip p {
    margin: 0;
    font-size: 12px;
  }
  
  .custom-calendar .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: inherit;
  }
  
  .custom-calendar .react-datepicker__month-container {
    width: 100%;
    padding: 20px;
  }
  
  .custom-calendar .react-datepicker__day-name,
  .custom-calendar .react-datepicker__day,
  .custom-calendar .react-datepicker__time-name {
    width: 40px;
    line-height: 40px;
    margin: 2px;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
  }
  
  .custom-calendar .react-datepicker__current-month {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .custom-calendar .react-datepicker__header {
    background-color: transparent;
    border: none;
    font-size: 18px;
  }
  