.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 10px;
}
.whiteContainer {
  padding: 16px;
  border-radius: 5.6px;
  background: #fff;
}
