.main_parent_div {
  background-color: #9cb20d;
}
.main {
  max-width: 1440px;
  width: 100%;
  /* min-height: 1024px; */
  min-height: 100vh;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.child {
  width: 100%;
  /* min-height: 604px; */
  /* height: 85vh; */
  background-color: #d0ee11;
  display: flex;
  align-items: center;
  flex-direction: row;
  /* padding: 100px 0; */
}

.heading {
  left: 0;
  padding: 100px 40px;
  width: 40%;
  display: flex;
  flex-direction: column;
}
.para {
  display: flex;
  width: auto;
  text-align: center;
}
.imageLogoContainer {
  margin-top: 20px;
}
.imageLogoContainer img {
  width: 200px;
  height: 60px;
}

.heading p {
  font-family: roboto;
  font-size: 50px;
  font-weight: 400;
  color: #333333;
}
#subs {
  font-weight: 900;
}
.details {
  width: 60%;
  height: 100%;
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 45px;
  border-top-left-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details_child {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

.detailChild-1 {
  flex: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.signup_options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detailChild-2 {
  flex: 15%;
}
.detailChild-2 {
  flex: 50%;
}

.input_field {
  margin: 31px auto;
  width: 100%;

  border-bottom: 1px solid #9d9d9d;
}
.input_field lable {
  margin-bottom: 10px;
}
.inputwrapper {
  display: flex;
}
.input_groups {
  display: flex;
  width: 100%;
  /* gap: 30px; */
}
.input_field input {
  width: 90%;
  border: none;
  outline: none;
  background: transparent;
}
.Login_btn {
  /* width: 90%; */
  width: 100%;
  padding: 20px;
  border-radius: 30px;
  background-color: #d0ee11;
  border: none;
  font-weight: 900;
  font-size: 16px;
  color: #333333;
}
.google_btn {
  width: 90%;
  padding: 20px;
  border-radius: 30px;
  background-color: transparent;
  border: 1px solid #d0ee11;
  font-weight: 900;
  font-size: 16px;
  color: #333333;
}
.signIn p {
  font-size: 30px;
  padding-top: 40px;
  font-weight: 900;
}
.create_acc {
  margin-top: 25px;
  text-align: center;
}
.signUp_text {
  font-size: 2rem;
}
.btn_grps {
  display: flex;
  width: 100%;
  gap: 30px;
}
.w_50 {
  width: 50%;
}
.Login_btn {
}
.Sign_up_here {
  text-align: center;
  padding-top: 20px;
  font-family: Roboto;
  color: #333333;
}
.Sign_up_here span a {
  text-decoration: none;
  color: #5b86e5;
}

@media (max-width: 767px) {
  .main {
    background-color: #d0ee11;
    /* height: 100vh; */
  }

  .heading {
    display: none;
  }
  .child {
    display: flex;
    /* align-items: center;
    justify-content: center;
    flex-direction: column; */
  }

  .details {
    width: 90%;
    border-radius: 45px;
    /* border: 2px solid black; */
    margin: auto;
    /* margin: 0 30%; */
  }
  .details_child {
    /* box-shadow: 5px 5px 5px 5px #D0EE11; */
    /* box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.15); */
    padding: 10px;
    border-radius: 45px;
  }
  .btn_grps {
    flex-direction: column;
    row-gap: 20px;
  }
  .w_50,
  .Login_btn,
  .google_btn {
    width: 100%;
  }
}
