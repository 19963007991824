.featureWrapper {
  align-items: center;
}
.iconcontainer {
  position: absolute;
  top: 3%;
  width: 150px;
  height: 140px;
}
.leftPart {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 45%;
}
.leftPartWithBorder {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 45%;
  padding-left: 23px;
  border-left: 20px solid transparent;
  border-image: linear-gradient(180deg, #d0ee11 0%, #10cf46 100%);
  border-image-slice: 1;
}
.rightPart {
  width: 40%;
}
.smartfeaturesContainer {
  gap: 22px;
}
.smartfeaturetextContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.smartFeatureTitle {
  color: #2f2f2f;

  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 24.2px */
}
.smarticoncontainer {
  width: 110px;
  height: 113px;
}

@media (min-width: 961px) and (max-width: 1280px) {
  .leftPart {
    gap: 25px;
  }
  .leftPartWithBorder {
    gap: 25px;
  }
  .rightPart {
    width: 45%;
  }
  .iconcontainer {
    width: 120px;
    height: 105px;
  }
}
@media (min-width: 601px) and (max-width: 960px) {
  .iconcontainer {
    display: none;
  }
  .leftPart {
    gap: 16px;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .leftPartWithBorder {
    gap: 16px;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .rightPart {
    width: 100%;
  }
  .smartfeaturesContainer {
    text-align: left;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .iconcontainer {
    display: none;
  }
  .leftPart {
    gap: 16px;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .leftPartWithBorder {
    gap: 16px;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .rightPart {
    width: 100%;
  }
  .smartFeatureTitle {
    font-size: 1.4rem;
  }
  .smartfeaturetextContainer {
    align-items: start;
  }
  .smarticoncontainer {
    width: 80px;
    height: 70px;
  }
  .featureWrapper {
    gap: 20px;
  }
  .smartfeaturesContainer {
    text-align: left;
    justify-content: center;
  }
}
