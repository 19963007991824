.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 100px 0px;
  justify-content: center;
  align-items: center;
  gap: 46px;
  /* padding-bottom: 100px; */
}
.pricing_head {
  color: #030303;
  text-align: center;

  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.featurewrapper {
  padding: 80px;
}
.cardsContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.featurecardcontainer {
  display: flex;
  width: 100%;
  padding: 29px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.632px;
  border-radius: 19.895px;
  border: 0.663px solid #dcdcdc;
  background: #fff;
  min-width: 400px;
  max-width: 400px;
}
.featurecardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
}
.premium_text {
  width: 100%;
}
.pricingSection {
  border-bottom: 1px solid #dcdcdc;
  padding: 7px 0px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
  width: 100%;
}
.featureFooter {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  row-gap: 20px;
}

.featurebuttonmain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.featurebuttonmain .featureBtn {
  min-width: 210px;
  max-width: 210px;
}
.featureBtn {
  display: flex;
  /* padding: 16.58px 90.192px; */
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  /* gap: 6.632px; */
  border-radius: 25.864px;
  background: #d0ee11;
  color: #030303;
  text-align: center;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.savingbtnText {
  color: #6d6253;
  width:-webkit-fill-available;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
}
.leftsec {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.prevfees {
  color: #808080;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150.5%;
  text-decoration-line: strikethrough;
}
.newfees {
  color: #2f2f2f;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 110%; /* 24.2px */
}
.annualfees {
  color: #808080;

  /* font-size: 1.4rem; */
  font-style: normal;
  font-weight: 400;
  line-height: 150.5%; /* 21.07px */
}
.features {
  color: #2f2f2f;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
 /*  line-height: 207.5%; */ /* 29.05px */
  line-height: 20px; /* 29.05px */
}

@media (min-width: 961px) and (max-width: 1280px) {
}
@media (min-width: 601px) and (max-width: 960px) {
  .featurecardcontainer {
    min-width: 270px;
    max-width: 400px;
  }
  .heroContainer {
    flex-wrap: wrap;
    padding: 50px 0px;
  }
  .featurewrapper {
    padding: 40px 20px;
  }
}
@media (max-width: 600px) {
  .featurecardcontainer {
    min-width: 100%;
    max-width: 100%;
  }
  .heroContainer {
    flex-wrap: wrap;
    padding: 40px 0px;
  }
  .cardsContainer {
    flex-wrap: wrap;
  }
  .featurewrapper {
    padding: 30px 16px;
  }
}
