/* * {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    outline: none;
}

#warning{
    color: red;
    font-size: 12px;
    margin-top: 10px;
}
#password_11{
    color: red;
    font-size: 12px;
    margin-top: 10px;
}
.login_select{
    text-decoration: none;
    color: black;
}

.main_singup_container{
    width: 500px;
    height: 470px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
    border: 1px solid gray;
}

.Singup_main_container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.Singup_main_container{
   
}

.mainContainer{
    margin-left: 76px;
    margin-top: 20px;
}
.Singup_Name{
    margin-top:20px;
}
.Singup_Name_inputbox{
    margin-top: 10px;
    width: 350px;
    height: 35px;
    border: 1px solid #736f6f;
   
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border: none;
    font-size: 14px;
    font-weight: 600;
    padding-left: 13px;
}

.Singup_Name_inputbox::placeholder {
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
    color: black;
}
.singup_create_acc {
    margin-top: 20px;
    margin-left: 70px;
}

.singup_create_ {
    font-size: 13px;
    color: #a29b90;
}
.singup_btn_class{
    margin-top: 20px;
}

.singup_heading{
    color:#6965e4;
} */

.main {
  background-color: #9cb20d;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.child {
  height: 90%;
  width: 90%;
  background-color: #d0ee11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.child_1 {
  flex: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.child_1_div {
  /* background-color: #5B86E5; */
  height: 70%;
  width: 90%;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-evenly;
}

.child_1_1 p {
  font-size: 40px;
  color: #333;
  padding-left: 20px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 1px;
}
#subs {
  color: #333;

  font-size: 40px;

  font-weight: 900;
  /* line-height: 60px; */
  /* letter-spacing: 2px; */
}

.child_2 {
  background-color: #fff;
  flex: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px 0 0 50px;
}

.child_2_div {
  width: 80%;
  /* height: 90%; */
  /* background-color: rebeccapurple; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.child_2_1 {
  flex: 13.3%;
  display: flex;
  align-items: center;
  justify-content: left;
  /* padding: 10px 0; */
}

.child_2_1 p {
  font-size: 30px;

  font-weight: 800;
  color: #333;

  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 133.333% */
  letter-spacing: 2.4px;
}

.child_2_2 button {
  padding: 10px 30px;
  color: #333333;
  border: 1.5px solid #5b86e5;
  border-radius: 5px;
  background-color: #ffffff;
}

.child_2_2 {
  flex: 13.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: row;
  padding: 10px 0;
}

.child_2_3 {
  flex: 13.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.child_2_3 p {
  font-size: 30px;
  color: #838383;
  font-weight: 800;
}

.child_2_4 {
  flex: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input_field {
  margin: 15px auto;
  width: 100%;
  padding: 10px 0 5px 0;
  border-bottom: 1px solid #9d9d9d;
}

.input_field input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  /* padding: 20px 0; */
}

.Login_btn {
  width: 60%;
  padding-top: 20px;
}

.Login_btn button {
  width: 100%;
  padding: 20px;
  border-radius: 30px;
  background-color: #d0ee11;
  border: none;

  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 1.28px;
}

.Sign_up_here {
  text-align: center;
  padding-top: 20px;
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1.28px;
}

.Sign_up_here span a {
  text-decoration: none;
  color: #5b86e5;
}
button {
  cursor: pointer;
}

.child_2_2 button:hover {
  color: white;
  background-color: #333;
  transition: 0.5s;
}

@media (max-width: 1330px) {
  .child_1_2 img {
    height: 10vh;
    width: 25vw;
  }
}
@media (max-width: 1098px) {
  .child_1_1 p {
    font-size: 1.8rem;
  }
  #subs {
    font-size: 1.8rem;
  }
}

@media (max-width: 840px) {
  .child_1 {
    display: none;
  }

  .child_2 {
    border-radius: 50px;
  }

  .child {
    background-color: #9cb20d;
  }
  .child_2_1 {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 547px) {
  .child_2_2 button {
    /* padding: 10px 10px; */
    padding: 0.5rem 0.5rem;
  }
  .Login_btn {
    width: 100%;
  }
}

@media (max-width: 428px) {
  .child_2_2 button {
    padding: 0.5rem 0.2rem;
  }
}
