.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 100px 0px;
  padding-bottom: 30px;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.imagecontainer {
  width: 97%;
  height: 30%;
}
.statsContainer {
  padding: 52px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  margin: auto;
  justify-content: space-between;
}
.iconcontainer {
  position: absolute;
  top: 8%;
  right: 0px;
  width: 20%;
  height: 83%;
}
.stats {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  margin: auto;
}
.aboutcontainer {
  padding: 56px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  text-align: center;
  align-items: center;
}
.ourvaluecontainer {
  padding: 56px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 32px;
}
.valuessection {
  display: flex;
  flex-wrap: wrap;
  row-gap: 32px;
  column-gap: 20px;
  justify-content: center;
}
.valuecardContainer {
  width: 30%;

  padding: 32px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.1);
}
.upperCard {
  display: flex;
  align-items: center;
  gap: 16px;
}
.cardheading {
  color: #777;
  text-align: center;

  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.missionContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 56px 0px;
}
.leftPart {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.missionContent {
  display: flex;
  align-items: center;
  gap: 9px;
}
.leftPart {
  width: 55%;
}
.rightPart {
  width: 45%;
}


@media (min-width: 961px) and (max-width: 1280px) {
  .cardheading {
    font-size: 2rem;
  }
  .valuecardContainer {
    padding: 20px;
  }
  .valueimages {
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 601px) and (max-width: 960px) {
  .heroContainer {
    padding-top: 50px;
  }
  .iconcontainer {
    width: 20%;
  }
  .valueimages {
    width: 50px;
    height: 50px;
  }
  .valuecardContainer {
    width: 48%;
    padding: 20px;
  }

  .leftPart {
    width: 100%;
    gap: 16px;
    text-align: center;
    margin-bottom: 30px;
  }
  .rightPart {
    width: 90%;
  }
  .statsContainer,
  .aboutcontainer,
  .ourvaluecontainer,
  .missionContainer {
    padding: 50px 0px;
    /* justify-content: center; */
  }
}
@media (max-width: 600px) {
  .valuessection {
    row-gap: 16px;
    width: 100%;
  }
  .heroContainer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .valueimages {
    width: 30px;
    height: 30px;
  }
  .valuecardContainer {
    width: 100%;
    padding: 10px;
  }
  .cardheading {
    font-size: 1.4rem;
  }
  .missionContainer {
    gap: 20px;
  }
  .upperCard {
    gap: 10px;
  }
  .leftPart {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .rightPart {
    width: 90%;
  }
  .aboutcontainer {
    padding: 56px 0px;
  }
  .statsContainer,
  .aboutcontainer,
  .ourvaluecontainer,
  .missionContainer {
    padding: 40px 0px;
    justify-content: center;
  }
}
