.filterContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: start;
}
.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgba(136, 136, 136, 0.4);
  background: #fff;
  width: 100%;
}
.searchfeild {
  width: 100%;
  border: none;
  color: #030303;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  background-color: transparent;
  padding: 0px !important;
}
.searchfeild::placeholder {
  color: #030303;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dateinputContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  margin-bottom: 20px;
}
.statusRow {
  display: flex;
  align-items: center;
  gap: 10px;
}
.amountrow {
  border-radius: 4px;
  border: 1px solid #d0ee11;
  display: flex;
  width: max-content;
  /* height: 24px; */
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  flex-direction: column;
  gap: 4px;
}
