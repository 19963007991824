.breadcrumText {
  color: rgba(3, 3, 3, 0.5);
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.headerContainer {
  display: flex;
  align-items: center;
  gap: 21px;
  margin-top: 10px;
}
.appImageContainer {
  border-radius: 18px;
  background: #fff;
  padding: 24px;
  max-width: 216px;
  max-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.appAboutContainer {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
}
.reminderdiv {
  width: 50%;
  display: flex;
  padding: 4px;
  border-radius: 4px;
  background: rgba(153, 153, 153, 0.19);
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.buttonType_1 {
  flex: 1;
  border-radius: 4px;
  background: linear-gradient(0deg, #d0ee11 0%, #d0ee11 100%), #a7bf0e;
  max-width: 217px;
  max-height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #030303;
  text-align: center;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.buttonType_2 {
  flex: 1;
  display: flex;
  gap: 10px;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #d0ee11;
  align-items: center;
  max-width: 217px;
  max-height: 40px;
  color: #030303;
  text-align: center;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.buttonType_3 {
  flex: 1;
  display: flex;
  gap: 10px;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #d0ee11;
  align-items: center;
  max-width: 217px;
  max-height: 40px;
  color: #030303;
  background: #d0ee11;
  text-align: center;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.discMainContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 18px;
}
