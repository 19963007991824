.SubscriptionSlider-page-alignment {
  .slick-arrow {
    width: 33px;
    height: 33px;
  }

  .slick-disabled {
    display: none !important;
  }
  .slick-prev {
    left: -17px !important;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 50%;
    color: transparent;
    outline: none;
    background: #fff;
    display: block;

    &::before {
      font-size: 33px !important;
      background-color: #d0ee11 !important;
      border-radius: 50%;
    }
  }
  .slick-next {
    right: -17px !important;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 50%;
    color: transparent;
    outline: none;
    background: #fff;
    display: block;

    &::before {
      font-size: 33px !important;
      background-color: #d0ee11 !important;
      border-radius: 50%;
    }
  }
}
