.addnewmainContainer {
  width: 100%;
}
.cardContainer {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
.cardWrapper {
  width: 15%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
.planecardcontainer {
  border-radius: 18px;
  border: 5px solid #cccbc9;
  background: #fff;
  max-width: 216px;
  max-height: 270px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lowerContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.thirdWidthContainer {
  width: calc(100% / 3.4);
}
