.tabsContainer {
  display: flex;
  align-items: center;
  gap: 34px;
  margin-bottom: 20px;
}
.tabtext {
  color: #030303;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tabActive {
  border-bottom: 1px solid #030303;
}
.tab {
  border: none;
}
.action_btn {
  border-radius: 4px !important;
  background: linear-gradient(0deg, #d0ee11 0%, #d0ee11 100%), #a7bf0e !important;

  padding: "10px 5px" !important;
}
.sm_text {
  color: #030303;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.para_bold {
  color: #030303;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.para_18_bold {
  color: #030303;
  /* font-family: Roboto; */
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.para_14 {
  color: #030303 !important;
  /* font-family: Roboto !important; */
  font-size: 1.4rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: none !important;
}
.calendarWrapper {
  background-color: white;
  width: 90%;
  margin: auto;
  max-height: 596px;
  border-radius: 7.327px;
  box-shadow: 0px 0px 15.56931px -3.66337px rgba(0, 0, 0, 0.25);
}
.calendar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}
.recurringcolumnheading {
  color: #273240;
  /* font-family: Roboto; */
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.5px;
}
