.mainContainer {
  border-radius: 18px;
  background: #fff;
  padding: 24px 18px;
  gap: 30px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
}
.upperContainer {
  padding-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  border-bottom: 1px solid #030303;
}
.amountdiv {
  display: flex;
  flex-direction: column;
}
.middlecontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.notificationcontainer {
  padding: 4px;
  border-radius: 4px;
  background: rgba(153, 153, 153, 0.19);
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.notificationBox {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 328px;
  width: 100%;
}
.notificationBox::-webkit-scrollbar {
  width: 0.5rem; /* Adjust the width to your preference */
  /* display: none; Hide the scrollbar */
}
.notificationItemcontainer {
  padding: 12px 9px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  gap: 8px;
}
