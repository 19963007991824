@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --color-dark: #030303;
  --color-desctext: #6d6253;
  --bg-color: #d0ee11;
  --bgcolor-sections: #f2f2f2;
}

html,
body {
  max-width: 100vw;
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
  background-color: #fff;
  display: block !important;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin,
.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel,
.MuiPickersCalendarHeader-root,
.MuiPickersCalendarHeader-labelContainer {
  font-size: 1.2rem !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button,
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiPickersCalendarHeader-switchViewButton svg {
  font-size: 2rem !important;
}

.css-2tlh8g {
  background-color: #f2f2f2;
  min-height: 100vh;
  padding: 0px !important;
  padding-left: 20px !important;
}

ul {
  list-style: none;
}

.ant-message {
  z-index: 10000;
  /* top: 300px !important;  */
}

.login_to_continue {
  /* margin: 100px 0px; */
  display: flex;
  /* height: 50vh; */
  height: 100%;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  height: 100%;
  min-height: 500px;
}

.text_big_login {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.header_1 {
  color: var(--color-dark);
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header_2 {
  font-size: 4rem;
  font-style: normal;
  line-height: normal;
}

.normal_sm_text {
  color: var(--color-dark);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desc_texts {
  color: var(--color-desctext);
  font-size: 1.8rem;
  font-style: normal;
  line-height: normal;
}

.dark_desc_texts {
  color: var(--color-dark);
  text-align: center;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text_sm {
  color: #030303;
  text-align: center;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text_very_sm {
  color: #030303;
  /* font-family: Roboto; */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text_very_very_small {
  color: #666;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.postloginHeading {
  color: #030303;

  font-size: 2.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sq_div {
  border: 1px solid #777;
  padding: 0px 5px;
  height: min-content;
}

.image {
  object-fit: contain;
  width: 60% !important;
  position: relative !important;
  height: unset !important;
}

.underlinetext {
  font-size: 1.2rem;
  font-style: normal;
  /* font-weight: 400; */
  line-height: normal;
  text-decoration-line: underline;
}

.cta_btn {
  display: inline-flex;
  padding: 16px 39px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--color-dark);
  color: #fff;
  text-align: center;

  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.navigation_button {
  display: inline-flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--color-dark);
  color: var(--color-dark);
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pageWrapper {
  max-width: 1440px;
  margin: auto;
  padding: 0px 40px;
  position: relative;
}

.fullSizeCalendar .react-datepicker__month-container {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.inlineCalendar .react-datepicker__month-container {
  width: 100%;
  height: 265px;
  display: flex;
  flex-direction: column;
}

.fullSizeCalendar .react-datepicker__month,
.inlineCalendar .react-datepicker__month {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.fullSizeCalendar .react-datepicker__week,
.inlineCalendar .react-datepicker__week {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.fullSizeCalendar .react-datepicker__current-month {
  font-size: 25px;
}

.fullSizeCalendar .react-datepicker__day,
.fullSizeCalendar .react-datepicker__day-name,
.inlineCalendar .react-datepicker__day,
.inlineCalendar .react-datepicker__day-name {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.inlineCalendar .react-datepicker__current-month,
.inlineCalendar .react-datepicker__day,
.inlineCalendar .react-datepicker__day-name {
  font-size: 10px;
}

.fullSizeCalendar .react-datepicker__day-names,
.inlineCalendar .react-datepicker__day-names {
  display: flex;
  /* margin: 20px 0px; */
}
.inlineCalendar .react-datepicker__day-names {
  display: flex;
  /* margin: 20px 0px 0 0; */
}

.fullSizeCalendar {
  width: 100%;
  height: 100%;
}

.wholeWidthContainer {
  width: 100%;
  margin-top: 70px;
}

.marg_16 {
  margin-bottom: 16px !important;
}

.marg_32 {
  margin-bottom: 32px !important;
}

.marg_100 {
  margin-bottom: 100px !important;
}

.img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

a {
  text-decoration: none !important;
}

input,
textarea {
  border-radius: 5.6px;
  background: #f5f5f5;
  padding: 8px 11px;
  color: #030303;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none !important;
  border: none;
}

input::placeholder,
textarea::placeholder {
  color: #b3b3b3;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

label {
  color: #333;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accordioncontainer {
  padding: 26px 39px;

  border-radius: 20px;
  border: 1px solid #dcdcdc;
}

.css-0 {
  margin-bottom: 30px !important;
}

.Mui-expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f5f5f5;
  gap: 44px;
}

.select1 > .ant-select-selector {
  border: none !important;
  outline: none !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
  padding-left: 0px !important;
}

.ant-select-selector {
  box-shadow: none !important;
  outline: none !important;
  border-color: #030303 !important;
}

.ant-select-selector:focus {
  outline: none !important;
  box-shadow: none !important;
}

.select1 > .ant-select-selector:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.ant-select-selection-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.slick-slide {
  max-width: max-content !important;
  gap: 20px !important;
}

.componentWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.slick-track {
  display: flex !important;
  gap: 20px !important;
}

.MuiFormControl-root {
  flex: 1 !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #273240;

  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 111.111% */
  letter-spacing: 0.5px;
}

.MuiDataGrid-cellContent {
  color: #030303;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.5px;
}

.custom-data-grid .MuiDataGrid-root {
  width: 100% !important;
  /* Use 100% width to fill the container */
}

/* Adjust the width of all columns equally */
.custom-data-grid .MuiDataGrid-columnHeader,
.custom-data-grid .MuiDataGrid-cell {
  width: calc(100% / 6) !important;
  /* Divide by the number of columns (3 in this case) */
  min-width: 100px !important;
  /* Set a minimum width for columns if needed */
}

.css-17jjc08-MuiDataGrid-footerContainer {
  flex-direction: row-reverse;
}

.css-1wnsr1i {
  border: none !important;
  border-radius: 5.6px !important;
  padding: 33px 25px !important;
}

.modal-select-class {
  background: #f5f5f5 !important;
  border: none !important;
  outline: none !important;
  padding: 10px !important;
  border-radius: 5.6px !important;
}

.modaldatepicker > div > .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px !important;
  background-color: #f5f5f5 !important;
  color: #030303;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* border: none !important; */
}

.modaldatepicker > div > .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.transactionMuiSelect > div {
  background-color: #fff !important;
}

.transactionMuiSelect > div > .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px !important;
  background-color: #fff !important;
  color: #030303;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* border: none !important; */
}

.css-204u17-MuiDataGrid-main {
  background-color: #fff;
  border-radius: 18px;
}

.css-1e2bxag-MuiDataGrid-root {
  border: none !important;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  margin-top: 16px !important;
  border-top: 0px !important;
}

.css-rtrcn9-MuiTablePagination-root:last-child {
  background-color: #fff !important;
  display: flex;
  align-items: center;
}

.css-1ndpvdd-MuiTableCell-root {
  color: #273240 !important;

  font-size: 1.8rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  /* 111.111% */
  letter-spacing: 0.5px !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin: 0px !important;
}

.css-119t8uk {
  min-height: 100vh !important;
}

.custom-tooltip {
  border-radius: 4px;
  background: #3e3e3e;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.platform-text {
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 181.818% */
  letter-spacing: 0.5px;
}

.react-datepicker {
  width: 100%;
  height: 100%;
  border-radius: 18px !important;
  background: #fff !important;
  box-shadow: 0px 0px 15.56931px -3.66337px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  overflow: hidden;
}

.small-caleander .react-datepicker {
  width: 100%;
  height: 100%;
  border-radius: 8px !important;
  background: #fff !important;
  box-shadow: 0px 0px 15.56931px -3.66337px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}

.react-datepicker__header {
  border-bottom: none !important;
}

li {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  /* width: max-content; */
  text-align: left;
}

ul {
  padding: 0px !important;
}

.Mui-expanded {
  background-color: #f5f5f5 !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.css-1fqi8hc-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: #f5f5f5 !important;
}

.css-1fqi8hc-MuiButtonBase-root-MuiListItemButton-root.active {
  background-color: #030303 !important;
}

.react-datepicker__day--highlighted {
  border-radius: 2px !important;
  background: rgba(208, 238, 17, 0.5) !important;
  color: #030303 !important;
}

.react-datepicker__header {
  background-color: #fff !important;
}

.react-datepicker__day--highlighted {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.react-datepicker__day--highlighted:hover .tooltiptext {
  visibility: visible;
}

.unsubscribedContainerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.unsubscribedContainer {
  display: inline-flex;
  padding: 49px 66px;
  align-items: center;
  gap: 60px;
  border-radius: 22px;
  border: 1px solid #d0ee11;

  background: #fff;

  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(0, 0, 0, 0.08);
}

.ant-spin-dot-item {
  background-color: #d0ee11 !important;
}

.ant-spin-text {
  color: #d0ee11 !important;
}

.unsubscribedHeading {
  color: #000;
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 38.4px */
}

.unsubscribedpara {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.72px;
}

.inputcontrol {
  font-size: 1.4rem;
}

.css-1yhpg23-MuiTableCell-root {
  font-size: 1.8rem !important;
}

@media (min-width: 961px) and (max-width: 1280px) {
  .header_1 {
    font-size: 5rem;
  }

  .header_2 {
    font-size: 3.5rem;
  }

  .desc_texts {
    font-size: 1.6rem;
  }

  .dark_desc_texts {
    font-size: 1.6rem;
  }

  .cta_btn {
    padding: 12px 34px;

    font-size: 1.6rem;
  }

  .text_sm {
    font-size: 1.4rem;
  }

  .marg_100 {
    margin-bottom: 80px !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .unsubscribedContainerWrapper {
    flex-direction: column;
  }

  .unsubscribedContainer {
    flex-direction: column;
  }

  .header_1 {
    font-size: 4rem;
  }

  .header_2 {
    font-size: 3rem;
  }

  .desc_texts {
    font-size: 1.4rem;
  }

  .cta_btn {
    padding: 12px 30px;

    font-size: 1.4rem;
  }

  .text_sm {
    font-size: 1.4rem;
  }

  .dark_desc_texts {
    font-size: 1.6rem;
  }

  .pageWrapper {
    padding: 0px 20px;
  }

  .marg_100 {
    margin-bottom: 50px !important;
  }

  .navigation_button {
    padding: 10px 14px;
    font-size: 1.2rem;
  }

  .normal_sm_text {
    font-size: 1.8rem;
  }
}

@media (max-width: 600px) {
  .unsubscribedContainerWrapper {
    flex-direction: column;
  }

  .unsubscribedContainer {
    flex-direction: column;
    padding: 20px;
  }

  .css-0 {
    margin-bottom: 20px !important;
  }

  .accordioncontainer {
    padding: 12px 12px;
  }

  .Mui-expanded {
    gap: 0px;
  }

  .header_1 {
    font-size: 3rem;
  }

  .header_2 {
    font-size: 2rem;
  }

  .desc_texts {
    font-size: 1.3rem;
  }

  .cta_btn {
    padding: 10px 28px;

    font-size: 1.3rem;
  }

  .text_sm {
    font-size: 1.3rem;
  }

  .pageWrapper {
    padding: 0px 16px;
  }

  .marg_100 {
    margin-bottom: 40px !important;
  }

  .navigation_button {
    padding: 10px 14px;
    font-size: 1.2rem;
  }

  .dark_desc_texts {
    font-size: 1.4rem;
  }

  .normal_sm_text {
    font-size: 1.3rem;
  }
}

.before-back::before {
  background: linear-gradient(180deg, #d0ee11 0%, #10cf46 100%), #d9d9d9;
}

.card-main {
  height: fit-content;
}

.faq-header {
  padding: 20px;
}

.faq-header svg {
  width: 20px;
  height: 20px;
}

.main-border {
  border-radius: 20px;
  border: 1px solid #dcdcdc;
}

.faq-body.hide {
  max-height: 0px;
  overflow: hidden;
  transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1);
}

.faq-body.show {
  max-height: 500vh;
  overflow: hidden;
  transition: 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
}

.faq-header svg {
  transition: 0.3s ease-in-out;
}

.main-border.main-border-change {
  background: #f5f5f5;
}

.main-border.main-border-change .faq-header svg {
  transform: rotate(180deg);
}

.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  height: 100%;
  z-index: 999;
}

.mobile-header.hide {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.mobile-header.show {
  max-height: 100vh;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.mobile-sidbar-height {
  height: calc(100vh - 65px);
  position: relative;
}

.mobile-sidebar-login-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.icon-hover:hover svg path {
  fill: #85951e;
}

.icon-hover svg path {
  fill: #777;
}

.icon-hover-storke:hover svg path {
  stroke: #85951e;
}

.icon-hover-storke svg path {
  stroke: #777;
}

.primary-gradient-bg {
  background: linear-gradient(0deg, #d0ee11 0%, #d0ee11 100%), #a7bf0e;
}

.profile-dropdown {
  position: absolute;
  width: 170px;
  border-radius: 6px;
  box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #e0e0e0;
  right: 0;
  top: 50px;
}

.profile-dropdown.hide {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  visibility: hidden;
}

.profile-dropdown.show {
  max-height: 100vh;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  visibility: visible;
}

.add-new-card-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-new-model-input {
  line-height: 24px;
  &::placeholder {
    font-size: 18px !important;
  }
}
.error-class {
  border: red 2px solid !important;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #d0ee11;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cus-dropdown-design {
  position: absolute;
  left: 0;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #fff;
}
.cus-dropdown-design.hide {
  max-height: 0px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  visibility: hidden;
  z-index: 9;
}

.cus-dropdown-design.show {
  /* max-height: 100vh; */
  max-height: 35vh;
  overflow-y: auto;
  transition: 0.5s ease-in-out;
  visibility: visible;
  z-index: 9;
}

.global-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 9999;
}

select:focus {
  outline: none;
}

.datepicker > div .Mui-error {
  border: none !important;
  background-color: #f5f5f5 !important;
}
.datepicker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 0 8px 14px !important;
}
.datepicker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  font-size: 18px !important;
}

.datepicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 999px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 999px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 999px;
}

input[type="radio"] {
  accent-color: #d0ee11;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-menu-hide {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease-out;
}

.sidebar-menu-show {
  max-height: 100vh;
  overflow: hidden;
  transition: 0.3s ease-out;
}

.icon-animation i {
  transition: 0.3s ease-in-out;
}

.icon-toogle i {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.DatePicker-main {
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  input {
    width: 100%;
    height: 3rem;
    font-size: 1rem !important;
    line-height: 1.5rem;

    &::placeholder {
      font-size: 1rem !important;
      line-height: 1.5rem;
    }
  }

  .react-datepicker__header {
    background-color: rgb(208 238 17 / var(--tw-bg-opacity)) !important;
  }
  .react-datepicker__navigation--previous {
    top: 8px;
  }
  .react-datepicker__navigation--next {
    top: 8px;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: rgb(208 238 17 / var(--tw-bg-opacity)) !important   ;
  }

  .react-datepicker__triangle::after {
    border-bottom-color: rgb(208 238 17 / var(--tw-bg-opacity)) !important   ;
  }

  .react-datepicker__day--selected {
    background-color: rgb(208 238 17 / var(--tw-bg-opacity)) !important;
    color: #000 !important;
  }

  .react-datepicker__navigation-icon::before {
    border-color: #000 !important;
  }

  .custom-datepicker {
    border: red 2px solid;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999999;
}

.mobile-sidbar-design {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.mobile-sidbar-design.hide {
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
}

.mobile-sidbar-design.show {
  transform: translateX(0%);
  transition: 0.3s ease-in-out;
}

.animation-trans i {
  transition: 0.3s ease-in-out;
}
.animation-trans-up i {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.custom-tab::after {
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  margin: auto;
  content: " ";
  color: transparent;
  background: #85951e;
  height: 2px;
  width: 0;
  transition: 0.4s ease-in-out;
}

.custom-tab:hover::after {
  width: 100%;
}
.active-custom-tab::after {
  width: 100%;
}
/* 
  .fullSizeCalendar .react-datepicker__day, .fullSizeCalendar .react-datepicker__day-name, .inlineCalendar .react-datepicker__day, .inlineCalendar .react-datepicker__day-name {
    width: 25px !important;
    height: 25px !important;
  } */

.inlineCalendar .react-datepicker__day,
.inlineCalendar .react-datepicker__day-name {
  width: 25px !important;
  height: 25px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  border-radius: 999px;
  right: 0;
  bottom: 0;
  background-color: #6d6d6d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #a7bf0e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #a7bf0e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.custom-datepicker {
  font-size: 14px !important;
}

.custom-otp-input {
  display: grid;
  grid-template-columns: repeat(6 , 1fr);
  gap: 20px;
}

.custom-otp-input input {
  width: 100% !important;
  margin: 0 !important;
  height: 60px !important;
  font-size: 16px !important;
}

@media (max-width:576px) {
  .custom-otp-input input {
    width: 100% !important;
    height: 50px !important;
    font-size: 16px !important;
  }
  .custom-otp-input {
    gap: 10px;
  }
}



.custom-multiselect {
  background: #f8f9fa; /* Background color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  padding: 0; /* Padding */
  font-size: 16px; /* Font size for the multiselect */
}

.custom-multiselect .search-box {
  border: none; /* Remove border */
  font-size: 16px; /* Font size for the search box */
  outline: none; /* Remove outline */
}

.custom-multiselect .search-box::placeholder {
  font-size: 12px; /* Placeholder font size */
  color: #888; /* Placeholder text color (optional) */
}

.custom-multiselect .option-container {
  background: #fff; /* Dropdown background */
  border: 1px solid #ccc; /* Dropdown border */
  border-radius: 5px; /* Rounded corners */
}

.custom-multiselect .option {
  font-size: 14px; /* Font size for dropdown options */
  padding: 5px; /* Option padding */
  cursor: pointer; /* Pointer cursor */
  background: #fff !important;
  color: #000;
}

.custom-multiselect .option:hover {
  background-color: #000 !important; /* Desired hover background color */
  color: #fff; /* Change text color on hover */
}

.custom-multiselect .option.selected {
  background-color: #000 !important; /* Background color for selected options */
  color: #fff; /* Text color for selected options */
}

.custom-multiselect .input-field {
  font-size: 12px; /* Font size for the search box */
}

.custom-multiselect .chips {
  background: #D0EE11 !important; /* Style for selected options */
}
.searchWrapper input::placeholder {
  font-size: 14px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.multiSelectContainer ul {
  max-height: 220px !important;
}