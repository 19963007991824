.contactHeroContainer {
  padding: 34px 0px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.leftSec {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.contactinfosec {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contactinfo {
  display: flex;
  align-items: center;
  gap: 23px;
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 56px 0px;
  align-items: center;
}
.formwrapper {
  border-radius: 5.6px;
  background: #fff;
  box-shadow: 8.23377px 8.23377px 19.55519px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 22px;
}
.upperForm {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 24px;
}
.lowerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
}
.inputfieldContainer {
  width: 48%;
}
.formcontrol {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.lowerForm {
  margin-top: 24px;
}
.formBtn {
  display: inline-flex;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5.6px;
  background: #d0ee11;
  color: #030303;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.faqcontainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding: 56px 0px;
}
.faqsdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.faqHeading {
  color: #2f2f2f;

  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 24.2px */
}
.faqAns {
  color: #2f2f2f;

  font-size: 2.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 24.2px */
}
.btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

@media (min-width: 961px) and (max-width: 1280px) {
}
@media (min-width: 601px) and (max-width: 960px) {
}
@media (max-width: 600px) {
  .contactHeroContainer {
    gap: 20px;
  }
  .leftSec {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .rightSec {
    width: 90%;
  }
  .inputfieldContainer {
    width: 100%;
  }
  .faqHeading {
    font-size: 1.6rem;
  }
  .faqAns {
    font-size: 1.6rem;
  }
}
